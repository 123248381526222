// import { AnalyticsStore } from 'bernie-plugin-mobx';
import { codeSplit, Controller, FetchOptions, Result } from 'bernie-core';
import { PageData } from 'bernie-http';
import { UiStore } from 'stores';
// import analyticsUtil from '../util/analytics-util';
// import '../client/temp-common-imports';

export class FlexDocumentController implements Controller {
  public pageId = 'FlexDocument';
  public path = '/:localeName/doc/:documentName';
  public routeName = 'flex-document';
  public bundles = [];

  public component = codeSplit( /* istanbul ignore next */
    () => import(/* webpackChunkName: "flex-document" */ '../views/flex-document-page'),
  );
  public exact = true;

  public fetch(options: FetchOptions): Promise<Result> {
    const { request, stores } = options;

    const params = request.params;
    const documentName = params?.documentName;

    const uiStore = stores?.get<UiStore>('uiStore');
    uiStore?.setPageName(this.pageId);
    uiStore?.setDocumentName(documentName);
    // const tcContainerUrl = uiStore?.tagCommanderUrl;

    // const analyticsStore: AnalyticsStore = stores && stores.get<AnalyticsStore>('analytics');
    // const context = request.context;

    // analyticsUtil.runAnalytics(this.pageId, analyticsStore, context, tcContainerUrl);

    return Promise.resolve({});
  }

  public fetchPageData(): Promise<PageData> {
    const pageData = {
      title: 'Affiliate Hub',
      seo: {
        robots: 'noindex',
      },
    };

    return Promise.resolve(pageData) as Promise<PageData>;
  }
}
