import { HomeController } from './home-controller';
import { FlexDocumentController } from './flex-document-controller';
import { UnsubscribeController } from './unsubscribe-controller';
import { CreatorVideoLandingController } from './creator-video-landing-controller';
import { CreatorVideoSubmitController } from './creator-video-submit-controller';
import { CreatorVideoConfirmationController } from './creator-video-confirmation-controller';
import { CreatorVideoAcceptanceAgreementController } from './creator-video-acceptance-agreement-controller';
import { CreatorVideoGuidelinesController } from './creator-video-guidelines-controller';
import { CreatorVideoTermsController } from './creator-video-terms-controller';
import { TravelVideoLandingController } from './travel-video-landing-controller';
import { TravelVideoGuidelinesController } from './travel-video-guidelines-controller';
import { TravelVideoAcceptanceAgreementController } from './travel-video-acceptance-agreement-controller';

export const controllers = [
  HomeController,
  CreatorVideoLandingController,
  CreatorVideoSubmitController,
  CreatorVideoConfirmationController,
  CreatorVideoAcceptanceAgreementController,
  CreatorVideoGuidelinesController,
  CreatorVideoTermsController,
  TravelVideoLandingController,
  TravelVideoGuidelinesController,
  TravelVideoAcceptanceAgreementController,
  FlexDocumentController,
  UnsubscribeController,
];
