// 3rd party
import { action, makeObservable, observable } from 'mobx';

// Bernie
import { Store } from 'bernie-plugin-mobx';

import { SerializedData } from 'bernie-core';
import { Logger, NOOP_LOGGER } from 'bernie-logger';

class UiStore extends Store {
  public pageName: string = '';
  public bffUrl: string = '';
  public flexDocumentPrefix: string = '';
  public documentName: string = '';
  public additionalParams: string = '';

  constructor(
    config,
    state: SerializedData = {},
    protected logger: Logger = NOOP_LOGGER,
  ) {
    super(state, logger);

    makeObservable(this, {
      pageName: observable,
      setPageName: action,
      flexDocumentPrefix: observable,
      setDocumentName: action,
      setAdditionalParams: action,
      bffUrl: observable,
    });

    this.bffUrl = `${config.app?.bff?.protocol}//${config.app?.bff?.hostname}`;
    this.flexDocumentPrefix = config.app?.flexDocumentPrefix;
  }

  public hydrate(data: SerializedData): void {
    Object.assign(this, data);
  }

  public setPageName(name: string): void {
    this.pageName = name;
  }

  public setDocumentName(documentName: string): void {
    this.documentName = documentName;
  }

  public setAdditionalParams(paramName: string): void {
    this.additionalParams = paramName;
  }
}

export { UiStore };
