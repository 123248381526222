import { codeSplit, Controller, FetchPageOptions } from 'bernie-core';
import { PageData } from 'bernie-http';

export class TravelVideoAcceptanceAgreementController implements Controller {
  public pageId = 'page.travel-video.acceptance-agreement';
  public path = '/:localeName/travel-video/video-acceptance-agreement';
  public routeName = 'travel-video-acceptance-agreement';
  public bundles = [];

  public component = codeSplit( /* istanbul ignore next */
    () => import(/* webpackChunkName: "travel-video-acceptance-agreement" */ '../views/travel-video-acceptance-agreement-page'),
  );
  public exact = true;

  // tslint:disable-next-line:prefer-function-over-method
  public fetch() {
    return Promise.resolve({});
  }

  // tslint:disable-next-line:prefer-function-over-method
  public fetchPageData(options: FetchPageOptions): Promise<PageData> {

    const pageData = {
      title: 'Travel Video Program - Expedia Group',
      seo: {
        canonical: `https://affiliates.expediagroup.com/${options.params.localeName}/travel-video`,
        description: 'Share videos of the amazing properties you stayed in with us. You could earn $150 for every video we feature on our sites, apps, social media, and more!'
      },
      clickstreamPageName: 'Travel Video Acceptance Agreement Page',
      clickstreamPageNameDetailed: 'Travel Video Acceptance Agreement Page',
    };

    return Promise.resolve(pageData) as Promise<PageData>;
  }
}
