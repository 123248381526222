import { codeSplit, Controller, FetchPageOptions } from 'bernie-core';
import { PageData } from 'bernie-http';

export class CreatorVideoSubmitController implements Controller {
  public pageId = 'page.creator-video.submit';
  public path = '/:localeName/creator-video/submit';
  public routeName = 'creator-video-submit';
  public bundles = [];

  public component = codeSplit( /* istanbul ignore next */
    () => import(/* webpackChunkName: "creator-video-submit" */ '../views/creator-video-submit-page'),
  );
  public exact = true;

  // tslint:disable-next-line:prefer-function-over-method
  public fetch() {
    return Promise.resolve({});
  }

  // tslint:disable-next-line:prefer-function-over-method
  public fetchPageData(options: FetchPageOptions): Promise<PageData> {

    const pageData = {
      title: 'Creator Video Program - Expedia Group',
      seo: {
        canonical: `https://affiliates.expediagroup.com/${options.params.localeName}/creator-video`,
        description: 'Share videos of the amazing properties you stayed in with us. You could earn $150 for every video we feature on our sites, apps, social media, and more!',
      },
      clickstreamPageName: 'Creator Video Submission Page',
      clickstreamPageNameDetailed: 'Creator Video Submission Page',
    };

    return Promise.resolve(pageData) as Promise<PageData>;
  }
}
