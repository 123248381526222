import { ClientFactories, Store } from 'bernie-plugin-mobx';
import { NOOP_LOGGER } from 'bernie-logger';

import { UiStore, UnsubscribeStore } from 'stores';

export const stores: ClientFactories = {
  uiStore(): Store {
    return new UiStore({}, {}, NOOP_LOGGER);
  },
  unsubscribeStore: (): Store => new UnsubscribeStore({}, {}, NOOP_LOGGER),
};
