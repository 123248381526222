// 3rd party imports
import axios from 'axios';

// bernie
import { SerializedData } from 'bernie-core';
import { Logger, NOOP_LOGGER } from 'bernie-logger';
import { Store } from 'bernie-plugin-mobx';

import { BffServiceConfig } from 'src/config/models';

class UnsubscribeStore extends Store {
  private readonly bffConfig: BffServiceConfig;

  constructor(config, state: SerializedData = {}, logger: Logger = NOOP_LOGGER) {
    super(state, logger);

    this.bffConfig = config.app?.bff;
  }

  hydrate(data: SerializedData): void {
    Object.assign(this, data);
  }

  public async setUnsubscribed(email: string) {
    try {
      const { protocol, hostname } = this.bffConfig;
      const bffService = `${protocol}//${hostname}/api/v1`;
      await axios.put(`${bffService}/unsubscribe/${email}`, {});

      return {}; //response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async setUnsubscribedByPartner(partnerId: string) {
    try {
      const { protocol, hostname } = this.bffConfig;
      const bffService = `${protocol}//${hostname}/api/v2`;
      await axios.put(`${bffService}/unsubscribe/${partnerId}`, {});

      return {}; //response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getPartnerForId(partnerId: string) {
    try {
      const { protocol, hostname } = this.bffConfig;
      const bffService = `${protocol}//${hostname}/api/v1`;
      const response = await axios.get(`${bffService}/partners/${partnerId}/emails`);

      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export { UnsubscribeStore };
