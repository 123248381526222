export type SupportedLocale = {
  langId: number;
  langCode: string;
  langLabel: string;
};

export const supportedLocales: SupportedLocale[] = [
  /* Keep the default locale as the first element. */
  { langId: 1033, langCode: 'en-us', langLabel: 'English (US)' },
  { langId: 2057, langCode: 'en-gb', langLabel: 'English (UK)' },
  { langId: 4105, langCode: 'en-ca', langLabel: 'English (CA)' },
  { langId: 3081, langCode: 'en-au', langLabel: 'English (AU)' },
  { langId: 5129, langCode: 'en-nz', langLabel: 'English (NZ)' },
  { langId: 18441, langCode: 'en-sg', langLabel: 'English (SG)' },
  { langId: 1036, langCode: 'fr-fr', langLabel: 'Français (FR)' },
  { langId: 3084, langCode: 'fr-ca', langLabel: 'Français (CA)' },
  { langId: 1031, langCode: 'de-de', langLabel: 'Deutsch' },
  { langId: 2058, langCode: 'es-mx', langLabel: 'Español (MX)' },
  { langId: 1041, langCode: 'ja-jp', langLabel: '日本語' },
  { langId: 1042, langCode: 'ko-kr', langLabel: '한국어' },
  { langId: 1028, langCode: 'zh-tw', langLabel: '繁體中文 (TW)' },
  { langId: 3076, langCode: 'zh-hk', langLabel: '繁體中文 (HK)' },
  { langId: 2052, langCode: 'zh-cn', langLabel: '简体中文' },
  { langId: 1046, langCode: 'pt-br', langLabel: 'Português (BR)' },
  { langId: 1035, langCode: 'fi-fi', langLabel: 'Suomi' },
  { langId: 1044, langCode: 'nb-no', langLabel: 'Norsk Bokmål' },
  { langId: 1030, langCode: 'da-dk', langLabel: 'Dansk' },
  { langId: 1053, langCode: 'sv-se', langLabel: 'Svenska' },
];
