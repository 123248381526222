import { codeSplit, Controller, Result } from 'bernie-core';
import { PageData } from 'bernie-http';

// import { UiStore } from '../stores';

export class UnsubscribeController implements Controller {
  public exact = true;
  public pageId = 'page.affhub.unsubscribe';
  public path = '/app/unsubscribe';
  public routeName = 'unsubscribe';
  public bundles = [];

  public component = codeSplit(() => import(/* webpackChunkName: "unsubscribe" */ '../views/unsubscribe-page'));

  public fetch(/*options: FetchOptions*/): Promise<Result> {
    // const { stores } = options;

    // const uiStore = stores?.get<UiStore>('uiStore');
    // uiStore?.setPageName(this.pageId);

    return Promise.resolve(undefined);
  }

  public fetchPageData(): Promise<PageData> {
    return Promise.resolve({ title: 'Unsubscribe' }) as Promise<PageData>;
  }
}
