import { codeSplit, Controller, FetchPageOptions, FetchOptions, Result } from 'bernie-core';
import { PageData } from 'bernie-http';
import { UiStore } from 'stores';

export class CreatorVideoLandingController implements Controller {
  public pageId = 'page.creator-video.landing';
  public path = '/:localeName/creator-video';
  public routeName = 'creator-video-landing';
  public bundles = [];

  public component = codeSplit( /* istanbul ignore next */
    () => import(/* webpackChunkName: "creator-video-landing" */ '../views/creator-video-landing-page'),
  );
  public exact = true;

  // tslint:disable-next-line:prefer-function-over-method
  public fetch(options: FetchOptions): Promise<Result> {
    const { request, stores } = options;
    const query = request.query;

    const uiStore = stores?.get<UiStore>('uiStore');

    if (query.pwaDialog) {
      uiStore?.setAdditionalParams('showCVDialog');
    }

    return Promise.resolve({});
  }

  // tslint:disable-next-line:prefer-function-over-method
  public fetchPageData(options: FetchPageOptions): Promise<PageData> {
    const pageData = {
      title: 'Creator Video Program - Expedia Group',
      seo: {
        canonical: `https://affiliates.expediagroup.com/${options.params.localeName}/creator-video`,
        description: 'Share videos of the amazing properties you stayed in with us. You could earn $150 for every video we feature on our sites, apps, social media, and more!'
      },
      clickstreamPageName: 'Creator Video Landing Page',
      clickstreamPageNameDetailed: 'Creator Video Landing Page',
    };

    return Promise.resolve(pageData) as Promise<PageData>;
  }
}
