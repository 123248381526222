import { codeSplit, Controller, FetchPageOptions } from 'bernie-core';
import { PageData, AlternateHrefLangLink } from 'bernie-http';
import { Localization } from 'bernie-l10n';
import { supportedLocales } from 'config/supported-locales';

export class HomeController implements Controller {
  public pageId = 'page.affhub.home';
  public path = '/:localeName/home';
  public routeName = 'home';
  public bundles = [];

  public component = codeSplit(() => import(/* webpackChunkName: "home" */ '../views/home-page'));
  public exact = true;

  // tslint:disable-next-line:prefer-function-over-method
  public fetch() {
    return Promise.resolve({});
  }

  // tslint:disable-next-line:prefer-function-over-method
  public fetchPageData(options: FetchPageOptions): Promise<PageData> {
    const localization = new Localization(options.context.locale);

    const pageData = {
      title: localization.formatText('home.page.title'),
      seo: {
        canonical: `https://affiliates.expediagroup.com/${options.params.localeName}/home`,
        additionalMeta: supportedLocales.map(
          (lang) =>
            new AlternateHrefLangLink(lang.langCode, `https://affiliates.expediagroup.com/${lang.langCode}/home`),
        ),
        description: localization.formatText('home.page.description'),
        robots: '',
        clickstreamPageName: 'affiliates_hub_home',
        clickstreamPageNameDetailed: 'Affiliate Hub Home',
      },
    };

    return Promise.resolve(pageData) as Promise<PageData>;
  }
}
